export function useDebounce(func: Function, timeout = 300) {
  let timer: NodeJS.Timeout

  return (...args: any) => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args)
    }, timeout)
  }
}
